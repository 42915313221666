<template>
  <div class="dropdown-input-wrapper">
    <InputLabel :id="id" :text="labelText" :mandatory="mandatory" />
    <CustomVueSelect
      :placeholder="placeholderText"
      :options="options"
      v-model="value"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { generateUID } from "../../utils/generateUID";

import InputLabel from "../elements/InputLabel.vue";
import CustomVueSelect from "../elements/CustomVueSelect";

export default {
  name: "DropdownInput",
  props: {
    labelText: String,
    mandatory: {
      type: Boolean,
      default: false,
    },
    options: Array,
    placeholderText: String,
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      id: generateUID(),
      value: this.modelValue,
    };
  },
  watch: {
    value: function (value) {
      this.$emit("update:modelValue", value);
    },
  },
  updated() {
    this.value = this.modelValue;
  },
  components: {
    InputLabel,
    CustomVueSelect,
  },
};
</script>

<style lang="scss" scoped>
.dropdown-input-wrapper {
  width: 100%;
  padding-bottom: 25px;
}
</style>
