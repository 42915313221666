<template>
  <div class="universal-month-picker-wrapper">
    <InputLabel :id="id" :text="labelText" />
    <MonthPicker
      placeholder="Choose date"
      v-model="date"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { generateUID } from "../../utils/generateUID";

import MonthPicker from "../elements/MonthPicker";
import InputLabel from "../elements/InputLabel";

export default {
  name: "UniversalMonthPicker",
  props: {
    labelText: String,
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      id: generateUID(),
      openedByDefault: false,
    };
  },
  watch: {
    date: function (value) {
      this.$emit("update:modelValue", value);
    },
  },
  computed: {
    date: {
      get: function () {
        return this.modelValue;
      },
      set: function (value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  components: {
    MonthPicker,
    InputLabel,
  },
};
</script>

<style></style>
