const getMonths = () => {
  return [
    {
      title: "Jan",
      value: "January",
    },
    {
      title: "Feb",
      value: "February",
    },
    {
      title: "Mar",
      value: "March",
    },
    {
      title: "Apr",
      value: "April",
    },
    {
      title: "May",
      value: "May",
    },
    {
      title: "Jun",
      value: "June",
    },
    {
      title: "Jul",
      value: "July",
    },
    {
      title: "Aug",
      value: "August",
    },
    {
      title: "Sep",
      value: "September",
    },
    {
      title: "Oct",
      value: "October",
    },
    {
      title: "Nov",
      value: "November",
    },
    {
      title: "Dec",
      value: "December",
    },
  ];
};

export { getMonths };
